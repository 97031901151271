import React from "react";

const CoreFeatures = () => {
  return (
    <section className="chat_core_features_area sec_pad">
      <div className="container">
        <div className="hosting_title chat_title text-center">
          <h2 className="wow fadeInUp" data-wow-delay="0.2s">
            The Power of YouGift
          </h2>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="chat_features_item wow fadeInUp">
              <div className="round">
                <img src={require("../../img/You Gift/PNG/img1.png")} alt="" />
              </div>
              <a href=".#">
                <h4>IMPROVE RETENTION</h4>
              </a>
              <p style={{ fontWeight: 600, color: "#333" }}>
                Using automatic technologies to free up the mind
              </p>
              <p>
                Tidio lets you get all your live chat, e-mail, and Messenger
                communication in one panel.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div
              className="chat_features_item wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div className="round">
                <img src={require("../../img/You Gift/PNG/img2.png")} alt="" />
              </div>
              <a href=".#">
                <h4>CUSTOMIZE & BE UNIQIUE </h4>
              </a>
              <p style={{ fontWeight: 600, color: "#333" }}>
                Using automatic technologies to free up the mind
              </p>
              <p>
                Tidio lets you get all your live chat, e-mail, and Messenger
                communication in one panel.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div
              className="chat_features_item wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="round">
                <img src={require("../../img/You Gift/PNG/img3.png")} alt="" />
              </div>
              <a href=".#">
                <h4>INCREASE REFERRALS</h4>
              </a>
              <p style={{ fontWeight: 600, color: "#333" }}>
                Using automatic technologies to free up the mind
              </p>
              <p>
                Tidio lets you get all your live chat, e-mail, and Messenger
                communication in one panel.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CoreFeatures;
