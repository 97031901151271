import React from "react";
import { BsFacebook , BsPinterest} from 'react-icons/bs';
import { FaFacebook } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import { SiYoutubemusic } from "react-icons/si";


const CustomFooter = ({ fClass, FooterData }) => {
  return (
    <footer className={`footer_area footer_area_four p-3 f_bg ${fClass}`}>
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                <a href="index.html" className="f-logo">
                   <img src={require("../../img/logo.png")} alt="" />
                </a>
                <p className="f_400 f_p f_size_15 mb-0 l_height34">
                  <span style={{color:"#ccc", fontSize:"14px"}}>@ YouGift 2023<br/> All right reserved</span>
                </p>
                </div>
                <div className="col-md-2">
                  <ul style={{listStyle:"none"}}>
                      <li>
                          <a href="/" style={{color:"#ccc"}}>Home</a>
                      </li>
                      <li>
                          <a href="/about" style={{color:"#ccc"}}>About</a>
                      </li>
                      <li>
                          <a href="/about" style={{color:"#ccc"}}>Personal</a>
                      </li>
                  </ul>
                </div>
                <div className="col-md-2">
                   <ul style={{listStyle:"none"}}>
                      <li>
                          <a href="/" style={{color:"#ccc"}}>Coprate</a>
                      </li>
                      <li>
                          <a href="/gifting" style={{color:"#ccc"}}>Gifting</a>
                      </li>
                      <li>
                          <a href="/contact" style={{color:"#ccc"}}>Contact</a>
                      </li>
                  </ul>
                </div>
                <div className="col-md-2">
                  <ul style={{listStyle:"none"}}>
                      <li>
                          <a href="/signin" style={{color:"#ccc"}}>Login</a>
                      </li>
                      <li>
                          <a href="/signup" style={{color:"#ccc"}}>Register</a>
                      </li>
                  </ul>
                </div>
                <div className="col-md-3">
                  <h6 style={{color:"#ccc"}}>Contact us</h6>
                  <p style={{color:"#ccc"}}>+001 234 567 890</p>
                  <div className="f_social_icon_two">
                    <a href="/">
                      <FaFacebook
                       style={{color:"#fff",
                       backgroundColor:"#3b5998",
                       width:"25px", height:"25px",
                       borderRadius:"100%"}}/>
                    </a>
                    <a href="/">
                      <AiFillTwitterCircle
                       style={{color:"#fff",
                       backgroundColor:"#00acee",
                       width:"25px", height:"25px",
                       borderRadius:"100%"}} />
                    </a>
                    <a href="/">
                      <SiYoutubemusic
                        style={{color:"#fff",
                        backgroundColor:"#c4302b",
                        width:"25px", height:"25px",
                        borderRadius:"100%"}}
                      />
                    </a>
                    <a href="/">
                      <BsPinterest
                        style={{color:"#fff",
                        backgroundColor:"#E4405F",
                        width:"25px", height:"25px",
                        borderRadius:"100%"}}
                      />
                    </a>
                  </div>
                </div>
            </div>
        </div>
    </footer>
  );
};
export default CustomFooter;
