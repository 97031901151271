import React from "react";

const EventFeaturesItem = ({ url, title, ptext, btn, image }) => {
  return (
    <div className="col-lg-3 col-sm-6">
      <div className="event_features_item text-center wow fadeInUp">
        <img src={require("../../img/home-event/" + image)} alt="" />
        <a href="/#">
          <h5>{title}</h5>
        </a>
        <p>{ptext}</p>
        <a href={url} className="e_features_btn">
          {btn}
        </a>
      </div>
    </div>
  );
};

export default EventFeaturesItem;
