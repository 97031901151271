import React from 'react'
import CustomNavbar from "../components/CustomNavbar";
import FooterData from "../components/Footer/FooterData";
import CustomFooter from '../components/Footer/CustomFooter';
import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle } from 'reactstrap';
import ShopPaginate from '../components/ShopPaginate';
function Shop() {
  return (
    <div className="body_wrapper">
    <CustomNavbar
      mClass="menu_four"
      cClass="custom_container p0"
      nClass="pl_120 mr-auto ml-auto"
      hbtnClass="menu_cus"
    />
    <section className='contact-info'>
      <div className='bg-contact-info'>
          <h2 style={{color:"#fff", fontWeight:"500", }}>Shop</h2>
      </div>
      <div className='container'>
          <div className='gift-list'>
              <div className='row'>
                  <div className='col-lg-4 col-md-4'>
                    <h4 style={{fontWeight:"bold",color:"#000",fontSize:"18px"}}>Filter</h4>
                    <Card
                       style={{marginBottom:"20px"}}>
                      <CardBody>
                          <ul className='filter-list'>
                            <li>
                               <div >
                                  <input type="checkbox" />
                                   <span>Birthday</span>
                                   <span style={{float:"right"}}>27</span>
                               </div>
                            </li>
                            <li>
                               <div >
                                  <input type="checkbox" />
                                   <span>Birthday</span>
                                   <span style={{float:"right"}}>27</span>
                               </div>
                            </li>
                            <li>
                               <div >
                                  <input type="checkbox" />
                                   <span>Birthday</span>
                                   <span style={{float:"right"}}>27</span>
                               </div>
                            </li>
                            <li>
                               <div >
                                  <input type="checkbox" />
                                   <span>Birthday</span>
                                   <span style={{float:"right"}}>27</span>
                               </div>
                            </li>
                            <li>
                               <div >
                                  <input type="checkbox" />
                                   <span>Birthday</span>
                                   <span style={{float:"right"}}>27</span>
                               </div>
                            </li>
                          </ul>
                      </CardBody>
                    </Card>
                  </div>
                  <div className='col-lg-8 col-md-8'>
                    <h4 style={{fontWeight:"bold", color:"#000",fontSize:"18px"}}>Birthday(23)</h4>
                     <div className='product-list'>
                         <div className='row'>
                             <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className='pdt-1'>
                                    <Card style={{
                                       boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                                    }}>
                                        <CardBody style={{textAlign:"center"}}>
                                          <img
                                              alt="Sample"
                                              src={require("../../../Home/src/img/product/apple-watch.png")}
                                              width="150px"
                                            />
                                        </CardBody>
                                    </Card>
                                    <h4>Apple Watch Series 6</h4>
                                    <p>Price <span>$399.98</span></p>
                                    <button className='btn round'
                                      style={{
                                          backgroundColor:"#e60c0a",
                                          color:"#fff",
                                          borderRadius:"100px",
                                          fontSize:"14px"
                                      }}
                                    >Add to My Gift</button>
                                </div>
                             </div>
                             <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className='pdt-1'>
                                    <Card style={{
                                       boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                                    }}>
                                        <CardBody style={{textAlign:"center"}}>
                                          <img
                                              alt="Sample"
                                              src={require("../../../Home/src/img/product/apple-watch.png")}
                                              width="150px"
                                            />
                                        </CardBody>
                                    </Card>
                                    <h4>Apple Watch Series 6</h4>
                                    <p>Price <span>$399.98</span></p>
                                    <button className='btn round'
                                      style={{
                                          backgroundColor:"#e60c0a",
                                          color:"#fff",
                                          borderRadius:"100px",
                                          fontSize:"14px"
                                      }}
                                    >Add to My Gift</button>
                                </div>
                             </div>
                             <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className='pdt-1'>
                                    <Card style={{
                                       boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                                    }}>
                                        <CardBody style={{textAlign:"center"}}>
                                          <img
                                              alt="Sample"
                                              src={require("../../../Home/src/img/product/apple-watch.png")}
                                              width="150px"
                                            />
                                        </CardBody>
                                    </Card>
                                    <h4>Apple Watch Series 6</h4>
                                    <p>Price <span>$399.98</span></p>
                                    <button className='btn round'
                                      style={{
                                          backgroundColor:"#e60c0a",
                                          color:"#fff",
                                          borderRadius:"100px",
                                          fontSize:"14px"
                                      }}
                                    >Add to My Gift</button>
                                </div>
                             </div>
                             <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className='pdt-1'>
                                    <Card style={{
                                       boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                                    }}>
                                        <CardBody style={{textAlign:"center"}}>
                                          <img
                                              alt="Sample"
                                              src={require("../../../Home/src/img/product/apple-watch.png")}
                                              width="150px"
                                            />
                                        </CardBody>
                                    </Card>
                                    <h4>Apple Watch Series 6</h4>
                                    <p>Price <span>$399.98</span></p>
                                    <button className='btn round'
                                      style={{
                                          backgroundColor:"#e60c0a",
                                          color:"#fff",
                                          borderRadius:"100px",
                                          fontSize:"14px"
                                      }}
                                    >Add to My Gift</button>
                                </div>
                             </div>
                             <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className='pdt-1'>
                                    <Card style={{
                                       boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                                    }}>
                                        <CardBody style={{textAlign:"center"}}>
                                          <img
                                              alt="Sample"
                                              src={require("../../../Home/src/img/product/apple-watch.png")}
                                              width="150px"
                                            />
                                        </CardBody>
                                    </Card>
                                    <h4>Apple Watch Series 6</h4>
                                    <p>Price <span>$399.98</span></p>
                                    <button className='btn round'
                                      style={{
                                          backgroundColor:"#e60c0a",
                                          color:"#fff",
                                          borderRadius:"100px",
                                          fontSize:"14px"
                                      }}
                                    >Add to My Gift</button>
                                </div>
                             </div>
                             <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className='pdt-1'>
                                    <Card style={{
                                       boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                                    }}>
                                        <CardBody style={{textAlign:"center"}}>
                                          <img
                                              alt="Sample"
                                              src={require("../../../Home/src/img/product/apple-watch.png")}
                                              width="150px"
                                            />
                                        </CardBody>
                                    </Card>
                                    <h4>Apple Watch Series 6</h4>
                                    <p>Price <span>$399.98</span></p>
                                    <button className='btn round'
                                      style={{
                                          backgroundColor:"#e60c0a",
                                          color:"#fff",
                                          borderRadius:"100px",
                                          fontSize:"14px"
                                      }}
                                    >Add to My Gift</button>
                                </div>
                             </div>
                             <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className='pdt-1'>
                                    <Card style={{
                                       boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                                    }}>
                                        <CardBody style={{textAlign:"center"}}>
                                          <img
                                              alt="Sample"
                                              src={require("../../../Home/src/img/product/apple-watch.png")}
                                              width="150px"
                                            />
                                        </CardBody>
                                    </Card>
                                    <h4>Apple Watch Series 6</h4>
                                    <p>Price <span>$399.98</span></p>
                                    <button className='btn round'
                                      style={{
                                          backgroundColor:"#e60c0a",
                                          color:"#fff",
                                          borderRadius:"100px",
                                          fontSize:"14px"
                                      }}
                                    >Add to My Gift</button>
                                </div>
                             </div>
                             <div className='text-center'>
                                <ShopPaginate/>
                             </div>
                         </div>
                     </div>
                  </div>
              </div>
          </div>
      </div>
    </section>
   <CustomFooter FooterData={FooterData} />
  </div>
  )
}

export default Shop
