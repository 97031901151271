import React from "react";
import EventFeaturesItem from "./EventFeaturesItem";

const EventFeatures = () => {
  return (
    <section className="event_features_area">
      <div className="container">
        <div className="hosting_title security_title text-center">
          <h2 className="wow fadeInUp titleThardSec" data-wow-delay="0.2s">
            Who Uses YouGift?
          </h2>
        </div>
        <div className="row event_features_inner">
          <EventFeaturesItem
            url=""
            image="leader.png"
            title="Sales Reps"
            ptext="Lost the plot lurgy on your bike mate Oxford hanky panky blow off haggle golly gosh do one."
            x
          />
          <EventFeaturesItem
            url=""
            image="meetup.png"
            title="HR Professionals"
            ptext="Lost the plot lurgy on your bike mate Oxford hanky panky blow off haggle golly gosh do one."
          />
          <EventFeaturesItem
            url=""
            image="chat.png"
            title="Customer Service Reps"
            ptext="Lost the plot lurgy on your bike mate Oxford hanky panky blow off haggle golly gosh do one."
          />
          <EventFeaturesItem
            url=""
            image="chat.png"
            title="Business Owners"
            ptext="Lost the plot lurgy on your bike mate Oxford hanky panky blow off haggle golly gosh do one."
          />
        </div>
      </div>
    </section>
  );
};
export default EventFeatures;
