import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import AgencyBanner from "../components/Banner/AgencyBanner";
import BannerData from "../components/Banner/BannerData";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import EventFeatures from "../components/Features/EventFeatures";
import ErpFeatures from "../components/Features/ErpFeatures";
import YouTubeBg from "../components/Features/YouTubeBg";
import CoreFeatures from "../components/Features/CoreFeatures";

const DigitalMarketing = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar
        mClass="menu_four"
        cClass="custom_container p0"
        nClass="pl_120 mr-auto ml-auto"
        hbtnClass="menu_cus"
      />
      <AgencyBanner BannerData={BannerData} />
      <EventFeatures />
      <YouTubeBg />
      <ErpFeatures />
      <CoreFeatures />
      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default DigitalMarketing;
