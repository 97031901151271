import React from 'react'
import CustomNavbar from "../components/CustomNavbar";
import FooterData from "../components/Footer/FooterData";
import CustomFooter from '../components/Footer/CustomFooter';
function AboutPage() {
  return (
    <div className="body_wrapper">
    <CustomNavbar
      mClass="menu_four"
      cClass="custom_container p0"
      nClass="pl_120 mr-auto ml-auto"
      hbtnClass="menu_cus"
    />
      <section className='contact-info'>
           <div className='bg-contact-info'>
              <h2 style={{color:"#fff", fontWeight:"500", }}>About Us</h2>
           </div>
          <div className='container'>
              <div className='row'>
                  <div className='col-md-4'>
                      <div className='' style={{
                          padding:"25px",
                          borderRadius:"10px",
                          border:"1px solid#ccc",
                          marginTop:"40px",
                          textAlign:"center"
                      }}>
                           <img
                              src={require("../../../Home/src/img/surprise.png")}
                              alt=""
                              width="100px"style={{marginBottom:"10px"}}

                            />
                            <h3
                               style={{fontSize:"25px",
                               color:"#000",fontWeight:"400",
                               }}>
                                Delivering quality gifts
                            <p style={{marginTop:"10px"}}>information on its origins</p>
                          </h3>
                      </div>
                  </div>
                  <div className='col-md-4'>
                      <div className='' style={{
                          padding:"25px",
                          borderRadius:"10px",
                          border:"1px solid#ccc",
                          marginTop:"40px",
                          textAlign:"center"

                      }}>
                            <img
                              src={require("../../../Home/src/img/gift.png")}
                              alt=""
                              width="100px"style={{marginBottom:"10px"}}

                            />
                            <h3
                             style={{fontSize:"25px",
                             color:"#000",fontWeight:"400",
                             }}
                            >Gifts for all occasions
                            <p style={{marginTop:"10px"}}>Variants and technical</p>
                          </h3>
                      </div>
                  </div>
                  <div className='col-md-4'>
                      <div className='' style={{
                          padding:"25px",
                          borderRadius:"10px",
                          border:"1px solid#ccc",
                          marginTop:"40px",
                          textAlign:"center"
                      }}>
                            <img
                              src={require("../../../Home/src/img/gift-box.png")}
                              alt=""
                              width="100px"style={{marginBottom:"10px"}}

                            />
                            <h3
                              style={{fontSize:"25px",
                              color:"#000",fontWeight:"400",
                              }}
                            >Great customer service
                            <p style={{marginTop:"10px"}}>random Lipsum generator</p>
                          </h3>
                      </div>
                  </div>

                  <div className='col-md-12'>
                        <div
                            style={{
                                marginTop:"30px",
                                padding:"20px"
                              }}>
                            <h3 style={{color:"#000", marginBottom:"30px"}}>
                               ABOUT <span style={{color:'#f40f0f'}}>YouGift</span>
                            </h3>
                           <div className='row'>
                               <div className='col-md-7'>
                                   <p>
                                        There are many variations of passages of Lorem Ipsum
                                        available, majority have suffered alteration in some
                                        form, by injected humour, or randomised words which don't
                                        look even slightly kn je believable There are manations
                                        of passages of Lorem Ipsum available, but the majority
                                        ahave suffered ami tar cholnay vulbo na alte ration.
                                        majority have suffered alteration in
                                   </p>

                                   <p>
                                        There are many variations of passages of Lorem Ipsum
                                        available, majority have suffered alteration in some
                                        form, by injected humour
                                   </p>
                                   <button className='btn btn-danger' style={{backgroundColor:"#f40f0f"}}>Try For Free</button>
                               </div>
                               <div className='col-md-5'>
                               <img
                                  src={require("../../../Home/src/img/about.jpg")}
                                  alt=""
                                  width="100%"style={{marginBottom:"10px"}}

                                />
                               </div>
                           </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
   <CustomFooter FooterData={FooterData} />
  </div>
  )
}

export default AboutPage
