import React from "react";
import { BsFacebook , BsPinterest} from 'react-icons/bs';
import { FaFacebook } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import { SiYoutubemusic } from "react-icons/si";


const FooterTwo = ({ fClass, FooterData }) => {
  return (
    <footer className={`footer_area footer_area_four p-3 f_bg ${fClass}`}>
      <div className="text-center">
        <a href="index.html" className="f-logo">
          <img src={require("../../img/logo.png")} alt="" />
        </a>
        <p className="f_400 f_p f_size_15 mb-0 l_height34">
          <span>@ YouGift 2023 All right reserved</span>
        </p>

        <div className="f_social_icon_two text-center">
          {/* {FooterData.socialIcon.map((item) => {
            return (
              <a href="/" key={item.id}>
                <i className={item.icon}></i>
              </a>
            );
          })} */}
              <a href="/">
                <FaFacebook size={25} color="#fff"/>
              </a>
              <a href="/">
                <AiFillTwitterCircle size={28} color="#fff"/>
              </a>
              <a href="/">
                <SiYoutubemusic size={25} color="#fff"/>
              </a>
              <a href="/">
                <BsPinterest size={25} color="#fff"/>
              </a>
        </div>
      </div>
    </footer>
  );
};
export default FooterTwo;
