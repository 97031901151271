import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Pagination, PaginationItem, PaginationLink
} from "reactstrap"

function ShopPaginate() {

    return (
        <div style={{
            display: 'block', width: 700, padding: 30
        }}>
            <Pagination>
                <PaginationItem>
                    <PaginationLink previous href="#" />
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">
                        1
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">
                        2
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">
                        3
                    </PaginationLink>
                </PaginationItem>

                <PaginationItem>
                    <PaginationLink next href="#" />
                </PaginationItem>
            </Pagination>
        </div >
    );
}

export default ShopPaginate;
