import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import DigitalMarketing from "./Pages/Digital-marketing";
import ScrollToTopRoute from "./ScrollToTopRoute";
import PriceingPlan from "./Pages/PriceingPlan";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import ContactInfo from "./Pages/ContactInfo";
import AboutPage from "./Pages/AboutPage";
import Gifting from "./Pages/Gifting";
import Shop from "./Pages/Shop";


const App = (props) => {
  useEffect(() => {
    props.hideLoader();
    // eslint-disable-next-line
  }, []);

  return (
    <Router>
      <Switch>
        <ScrollToTopRoute exact path="/" component={DigitalMarketing} />
        <ScrollToTopRoute exact path="/priceingplan" component={PriceingPlan} />
        <ScrollToTopRoute exact path="/signin" component={SignIn} />
        <ScrollToTopRoute exact path="/signup" component={SignUp} />
        <ScrollToTopRoute exact path="/contact" component={ContactInfo} />
        <ScrollToTopRoute exact path="/about" component={AboutPage} />
        <ScrollToTopRoute exact path="/gifting" component={Gifting} />
        <ScrollToTopRoute exact path="/shop" component={Shop} />
      </Switch>
    </Router>
  );
};

export default App;
