import React from 'react'
import CustomNavbar from '../components/CustomNavbar'
import { FaCheck } from 'react-icons/fa';
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import CustomFooter from '../components/Footer/CustomFooter';
function PriceingPlan() {
  return (
    <div  className="body_wrapper">
      <CustomNavbar
        mClass="menu_four"
        cClass="custom_container p0"
        nClass="pl_120 mr-auto ml-auto"
        hbtnClass="menu_cus"
      />
        <section className='pad_top'>
           <div className="container">
               <div className='text-center'>
                  <h3 style={{color:"#fff", fontSize:"35px", fontWeight:"400", marginTop:"20px",}}>YouGift Plans & Priceing</h3>
                  <p style={{color:"#fff", fontSize:"16px", fontWeight:"300", marginBottom:"50px"}}>We solve the problem Of remembering the importance <br/>
                     of gifting at less than 1$ a day.
                  </p>
                  <div className='plan-lis'>
                      <div className='row'>
                          <div class="col-lg-4 col-sm-3 mb-5 mb-lg-0 st-1">
                          <h1 class="h5 text-uppercase  mb-0 p-2" style={{backgroundColor:"#eaeaea", color:"#0979df"}}>Basic</h1>
                            <div class="bg-white p-5  shadow">
                              <h2 class="h4 font-weight-bold">$9<span class="text-small font-weight-normal ml-2">/ month</span></h2>
                                  <hr></hr>
                                  <div class="custom-separator my-4 mx-auto bg-warning"></div>
                                  <ul class="list-unstyled  text-small text-left">
                                    <li class="mb-3" style={{color:"#000",fontWeight:"500", marginBottom:"0px"}}>
                                      <FaCheck size={18} color='green'/> Lorem
                                    </li>
                                    <li class="mb-3" style={{color:"#000",fontWeight:"500"}}>
                                      <FaCheck size={18} color='green'/> Lorem
                                    </li>
                                    <li class="mb-3" style={{color:"#000",fontWeight:"500"}}>
                                      <FaCheck size={18} color='green'/> Lorem
                                    </li>
                                    <li class="mb-3" style={{color:"#000",fontWeight:"500"}}>
                                      <FaCheck size={18} color='green'/> Lorem
                                    </li>
                                    <li class="mb-3" style={{color:"#000",fontWeight:"500"}}>
                                      <FaCheck size={18} color='green'/> Lorem
                                    </li>
                                  </ul>
                                   <a href="#" class="btn btn-primary btn-block p-2 shadow rounded-pill">Subscribe</a>
                            </div>
                          </div>
                          <div class="col-lg-4 col-sm-3 mb-5 mb-lg-0 st-2">
                          <h1 class="h5 text-uppercase  mb-0 p-2" style={{backgroundColor:"#eaeaea", color:"#0979df"}}>Professional Plan</h1>
                            <div class="bg-white p-5  shadow">
                              <h2 class="h4 font-weight-bold">$9<span class="text-small font-weight-normal ml-2">/ month</span></h2>
                                  <hr></hr>
                                  <div class="custom-separator my-4 mx-auto bg-warning"></div>
                                  <ul class="list-unstyled  text-small text-left">
                                  <li class="mb-3" style={{color:"#000",fontWeight:"500", marginBottom:"0px"}}>
                                      <FaCheck size={18} color='green'/> Lorem
                                    </li>
                                    <li class="mb-3" style={{color:"#000",fontWeight:"500"}}>
                                      <FaCheck size={18} color='green'/> Lorem
                                    </li>
                                    <li class="mb-3" style={{color:"#000",fontWeight:"500"}}>
                                      <FaCheck size={18} color='green'/> Lorem
                                    </li>
                                    <li class="mb-3" style={{color:"#000",fontWeight:"500"}}>
                                      <FaCheck size={18} color='green'/> Lorem
                                    </li>
                                    <li class="mb-3" style={{color:"#000",fontWeight:"500"}}>
                                      <FaCheck size={18} color='green'/> Lorem
                                    </li>
                                    <li class="mb-3" style={{color:"#000",fontWeight:"500"}}>
                                      <FaCheck size={18} color='green'/> Lorem
                                    </li>
                                    <li class="mb-3" style={{color:"#000",fontWeight:"500"}}>
                                      <FaCheck size={18} color='green'/> Lorem
                                    </li>
                                  </ul>
                                   <a href="#" class="btn btn-primary btn-block p-2 shadow rounded-pill">Subscribe</a>
                            </div>
                          </div>
                          <div class="col-lg-4 col-sm-3 mb-5 mb-lg-0 st-3">
                          <h1 class="h5 text-uppercase  mb-0 p-2" style={{backgroundColor:"#eaeaea", color:"#f40f0f"}}>Enterprise Plan</h1>
                            <div class="bg-white p-5  shadow">
                              <h2 class="h4 font-weight-bold">$9<span class="text-small font-weight-normal ml-2">/ month</span></h2>
                                  <hr></hr>
                                  <div class="custom-separator my-4 mx-auto bg-warning"></div>
                                  <ul class="list-unstyled  text-small text-left">
                                  <li class="mb-3" style={{color:"#000",fontWeight:"500", marginBottom:"0px"}}>
                                      <FaCheck size={18} color='green'/> Lorem
                                    </li>
                                    <li class="mb-3" style={{color:"#000",fontWeight:"500"}}>
                                      <FaCheck size={18} color='green'/> Lorem
                                    </li>
                                    <li class="mb-3" style={{color:"#000",fontWeight:"500"}}>
                                      <FaCheck size={18} color='green'/> Lorem
                                    </li>
                                    <li class="mb-3" style={{color:"#000",fontWeight:"500"}}>
                                      <FaCheck size={18} color='green'/> Lorem
                                    </li>
                                    <li class="mb-3" style={{color:"#000",fontWeight:"500"}}>
                                      <FaCheck size={18} color='green'/> Lorem
                                    </li>
                                  </ul>
                                   <a href="#" class="btn btn-danger btn-block p-2 shadow rounded-pill">Subscribe</a>
                            </div>
                          </div>
                      </div>
                  </div>


               </div>
            </div>
        </section>
          {/* FAQ */}
                  <div className="faq-content">
                       <div className='row'>
                           <div className='col-md-2'></div>
                           <div className='col-md-8'>
                            <h2 style={{textAlign:"center", marginBottom:"40px"}}>
                              Frequntly Asked Question
                            </h2>
                               <div className='row'>
                                  <div className='col-md-6'>
                                      <div className='faq-text text-left mb-10'>
                                        <h5 style={{fontSize:"18px",fontWeight:"500"}}>Lorem ipsum dolor sit amet, consectetur?</h5>
                                        <p style={{fontSize:"15px",fontWeight:"300"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                           sed do eiusmod tempor incididunt ut labore et dolore
                                           magna aliqua.
                                        </p>
                                      </div>
                                  </div>
                                  <div className='col-md-6'>
                                      <div className='faq-text text-left mb-10'>
                                        <h5 style={{fontSize:"18px",fontWeight:"500"}}>Lorem ipsum dolor sit amet, consectetur?</h5>
                                        <p style={{fontSize:"15px",fontWeight:"300"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                           sed do eiusmod tempor incididunt ut labore et dolore
                                           magna aliqua.
                                        </p>
                                      </div>
                                  </div>
                                  <div className='col-md-6'>
                                      <div className='faq-text text-left mb-10'>
                                        <h5 style={{fontSize:"18px",fontWeight:"500"}}>Lorem ipsum dolor sit amet, consectetur?</h5>
                                        <p style={{fontSize:"15px",fontWeight:"300"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                           sed do eiusmod tempor incididunt ut labore et dolore
                                           magna aliqua.
                                        </p>
                                      </div>
                                  </div>
                                  <div className='col-md-6'>
                                      <div className='faq-text text-left mb-10'>
                                        <h5 style={{fontSize:"18px",fontWeight:"500"}}>Lorem ipsum dolor sit amet, consectetur?</h5>
                                        <p style={{fontSize:"15px",fontWeight:"300"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                           sed do eiusmod tempor incididunt ut labore et dolore
                                           magna aliqua.
                                        </p>
                                      </div>
                                  </div>
                                  <div className='col-md-6'>
                                      <div className='faq-text text-left mb-10'>
                                        <h5 style={{fontSize:"18px",fontWeight:"500"}}>Lorem ipsum dolor sit amet, consectetur?</h5>
                                        <p style={{fontSize:"15px",fontWeight:"300"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                           sed do eiusmod tempor incididunt ut labore et dolore
                                           magna aliqua.
                                        </p>
                                      </div>
                                  </div>
                                  <div className='col-md-6'>
                                      <div className='faq-text text-left mb-10'>
                                        <h5 style={{fontSize:"18px",fontWeight:"500"}}>Lorem ipsum dolor sit amet, consectetur?</h5>
                                        <p style={{fontSize:"15px",fontWeight:"300"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                           sed do eiusmod tempor incididunt ut labore et dolore
                                           magna aliqua.
                                        </p>
                                      </div>
                                  </div>
                               </div>
                           </div>
                           <div className='col-md-2'></div>
                       </div>
                  </div>
          {/* FAQ Close */}

          {/* new section */}
            <div className='bottom-section'>
               <h2 style={{fontSize:"25px", color:"#fff"}}>Study show that company that gift their Employyees & Clients<br></br>
                   see a 290% Retentions
                </h2>
                <a href="#" class="btn btn-danger btn-block p-2 shadow rounded-pill" style={{width:"150px", display:"inline-block"}}>Start your trial</a>
            </div>
          {/* new section close */}
       <CustomFooter FooterData={FooterData} />
    </div>
  )
}

export default PriceingPlan
