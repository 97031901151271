import React from "react";
import Reveal from "react-reveal/Reveal";

const AgencyBanner = (props) => {
  const { BannerData } = props;
  return (
    <section className="agency_banner_area bg_color">
      <img
        className="banner_shap imageBg"
        src={require("../../img/home4/banner_bg.jpg")}
        alt=""
      />
      <div className="container custom_container">
        <div className="row">
          <div className="col-lg-5 ">
            <Reveal effect="fadeInRight">
              <img
                className="protype_img wow fadeInRight"
                data-wow-delay="0.3s"
                src={require("../../img/home4/banner_img.jpg")}
                alt=""
                width="100%"
              />
            </Reveal>
          </div>
          <div className="col-lg-7 d-flex align-items-center">
            <div className="agency_content">
              <Reveal effect="fadeInUp">
                {BannerData.AgencyText.map((Agency) => {
                  return (
                    <React.Fragment key={Agency.id}>
                      <h2
                        className="f_700 t_color3 mb_40 wow fadeInLeft"
                        data-wow-delay="0.3s"
                      >
                        {Agency.btitle}
                      </h2>
                      <p
                        className="f_400 l_height28 wow fadeInLeft subTitle"
                        data-wow-delay="0.4s"
                      >
                        {Agency.description}
                      </p>
                    </React.Fragment>
                  );
                })}
                <div className="action_btn d-flex align-items-center mt_60">
                  <a
                    href="/#"
                    className="btn_hover agency_banner_btn wow fadeInLeft btnStyleBaner"
                    data-wow-delay="0.5s"
                  >
                    START YOUR TRIAL
                  </a>
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AgencyBanner;
