import React from "react";
import YouTubeFrem from "./YouTubeFrem";

const YouTubeBg = () => {
  return (
    <section className="bgColor">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="vd-box">
              <img src={require("../../img/YouGift-web.jpg")} alt="" />
              <div className="vd-btn">
                <YouTubeFrem />
              </div>
              <div className="btn-box text-center mt-3">
                <a
                  href="/#"
                  className="btn_hovers agency_banner_btns wow fadeInLeft youTubebtnStyle"
                  data-wow-delay="0.5s"
                >
                  START YOUR TRIAL
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default YouTubeBg;
