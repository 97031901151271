import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import { FaCheck } from 'react-icons/fa';

const SignIn = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar
        mClass="menu_four"
        cClass="custom_container p0"
        nClass="pl_120 mr-auto ml-auto"
        hbtnClass="menu_cus"
      />
       <section className="sign-bg">
          <div className="container">
              <div className="row">
                  <div className="col-md-12">
                     <div className="sign-box">
                        <div className="row">
                           <div className="col-md-8">
                              <div className="right-content text-center">
                                  <h4 style={{color:"#007bff"}}>Try YouGift for Free</h4>
                                   <p style={{fontSize:"12px", color:"#000", fontWeight:"500"}}>Start 30 days free trail get access to the Professional version <br/>
                                     and use all of YouGifts features before you spend a panny.
                                   </p>
                                   <p style={{fontSize:"14px",color:"#000",fontWeight:"500"}}>Already have not  an Account?
                                    <span style={{color:"#007bff"}}>
                                      <a href="/signup">
                                        Register Here
                                      </a>
                                      </span>
                                    </p>
                                    <form>
                                      <input type="email" placeholder="Email" className="form-control"/>
                                      <br></br>
                                      <input type="Password" placeholder="Email" className="form-control"/>
                                      <br></br>
                                      <button className="btn btn-primary" style={{width:"100%"}}>Login Now</button>
                                      <small style={{textAlign:"left", color:"#000" }}>You are agree to our <span style={{color:"#0069d9"}}> Terms & Conditions</span></small>
                                  </form>
                              </div>
                           </div>
                           <div className="col-md-4">
                                <div className="left-content">
                                   <h4 style={{color:"#000", fontSize:"20px"}}>The Power of YouGift</h4>
                                   <p style={{fontSize:"12px",color:"#000",fontWeight:"500"}}>Exprience branding like you have naver seen before</p>
                                   <ul class="list-unstyled  text-small">
                                       <li
                                          style={{color:"#000",fontWeight:"400",
                                          marginBottom:"0px", fontSize:"12px", lineHeight:"30px"}}>
                                          <FaCheck size={18} color='Green' style={{marginRight:"6px"}}/>
                                          Lorem ipsum dolor sit amet, consectetur
                                       </li>
                                       <li
                                          style={{color:"#000",fontWeight:"400",
                                          marginBottom:"0px", fontSize:"12px", lineHeight:"30px"}}>
                                          <FaCheck size={18} color='Green' style={{marginRight:"6px"}}/>
                                          Lorem ipsum dolor sit amet, consectetur
                                       </li>
                                       <li
                                          style={{color:"#000",fontWeight:"400",
                                          marginBottom:"0px", fontSize:"12px", lineHeight:"30px"}}>
                                          <FaCheck size={18} color='Green' style={{marginRight:"6px"}}/>
                                          Lorem ipsum dolor sit amet, consectetur
                                       </li>
                                       <li
                                          style={{color:"#000",fontWeight:"400",
                                          marginBottom:"0px", fontSize:"12px", lineHeight:"30px"}}>
                                          <FaCheck size={18} color='Green' style={{marginRight:"6px"}}/>
                                          Lorem ipsum dolor sit amet, consectetur
                                       </li>
                                   </ul>
                                </div>
                            </div>
                        </div>
                     </div>
                  </div>
              </div>
          </div>
       </section>
      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default SignIn;
