import React from "react";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";
import {
  Navbar,
  NavItem,
  NavbarToggler,
  Collapse,
  NavLink,
  Nav,
  NavbarBrand,
} from "reactstrap";
import { FaBars } from "react-icons/fa";

const CustomNavbar = (props) => {
  const { mClass, cClass, slogo, hbtnClass } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
      <header className="header_area bgcolor">
        <Navbar color="" light expand="md">
          <NavbarBrand href="/">
            <img src={require("../img/logo2.png")} alt="" />
            <img src={require("../img/logo.png")} alt="logo" width="100px" />
          </NavbarBrand>
          <NavbarToggler
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <FaBars size={25} color="#fff" />
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/">HOME</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/shop">SHOP</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/priceingplan">PRICING</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/signin">LOGIN</NavLink>
              </NavItem>
            </Nav>
            <div className="">
              <a
                className={`btn_get btn_hover bgBtnColor ${hbtnClass}`}
                href="#get-app"
              >
                (800) 530 2853
              </a>
              <a
                className={`btn_get btn_hover trialBtnStyle ${hbtnClass}`}
                href="#get-app"
              >
                START YOUR TRIAL
              </a>
            </div>
          </Collapse>
        </Navbar>
      </header>
    </Sticky>
  );
};

export default CustomNavbar;
