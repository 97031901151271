import React from 'react'
import CustomNavbar from "../components/CustomNavbar";
import FooterData from "../components/Footer/FooterData";
import CustomFooter from '../components/Footer/CustomFooter';
import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle } from 'reactstrap';
function Gifting() {
  return (
    <div className="body_wrapper">
    <CustomNavbar
      mClass="menu_four"
      cClass="custom_container p0"
      nClass="pl_120 mr-auto ml-auto"
      hbtnClass="menu_cus"
    />
    <section className='contact-info'>
      <div className='bg-contact-info'>
          <h2 style={{color:"#fff", fontWeight:"500", }}>Gifting</h2>
      </div>
      <div className='container'>
          <div className='gift-list'>
              <div className='row'>
                  <div className='col-md-3'>
                    <Card
                       style={{marginBottom:"20px"}}>
                      <img
                        alt="Sample"
                        src={require("../../../Home/src/img/gift-item.jpg")}

                      />
                      <CardBody>
                        <CardTitle tag="h5">
                          Lorem Ipsum
                        </CardTitle>
                        <CardText>
                          Some quick example text to build on the card title and make up the bulk of the card‘s content.
                        </CardText>
                      </CardBody>
                    </Card>
                  </div>
                  <div className='col-md-3'>
                    <Card
                       style={{marginBottom:"20px"}}>
                      <img
                        alt="Sample"
                        src={require("../../../Home/src/img/gift-item.jpg")}

                      />
                      <CardBody>
                        <CardTitle tag="h5">
                          Lorem Ipsum
                        </CardTitle>
                        <CardText>
                          Some quick example text to build on the card title and make up the bulk of the card‘s content.
                        </CardText>
                      </CardBody>
                    </Card>
                  </div>
                  <div className='col-md-3'>
                    <Card
                       style={{marginBottom:"20px"}}>
                      <img
                        alt="Sample"
                        src={require("../../../Home/src/img/gift-item.jpg")}

                      />
                      <CardBody>
                        <CardTitle tag="h5">
                          Lorem Ipsum
                        </CardTitle>
                        <CardText>
                          Some quick example text to build on the card title and make up the bulk of the card‘s content.
                        </CardText>
                      </CardBody>
                    </Card>
                  </div>
                  <div className='col-md-3'>
                    <Card
                       style={{marginBottom:"20px"}}>
                      <img
                        alt="Sample"
                        src={require("../../../Home/src/img/gift-item.jpg")}

                      />
                      <CardBody>
                        <CardTitle tag="h5">
                          Lorem Ipsum
                        </CardTitle>
                        <CardText>
                          Some quick example text to build on the card title and make up the bulk of the card‘s content.
                        </CardText>
                      </CardBody>
                    </Card>
                  </div>
                  <div className='col-md-3'>
                    <Card
                       style={{marginBottom:"20px"}}>
                      <img
                        alt="Sample"
                        src={require("../../../Home/src/img/gift-item.jpg")}

                      />
                      <CardBody>
                        <CardTitle tag="h5">
                          Lorem Ipsum
                        </CardTitle>
                        <CardText>
                          Some quick example text to build on the card title and make up the bulk of the card‘s content.
                        </CardText>
                      </CardBody>
                    </Card>
                  </div>
                  <div className='col-md-3'>
                    <Card
                       style={{marginBottom:"20px"}}>
                      <img
                        alt="Sample"
                        src={require("../../../Home/src/img/gift-item.jpg")}

                      />
                      <CardBody>
                        <CardTitle tag="h5">
                          Lorem Ipsum
                        </CardTitle>
                        <CardText>
                          Some quick example text to build on the card title and make up the bulk of the card‘s content.
                        </CardText>
                      </CardBody>
                    </Card>
                  </div>
                  <div className='col-md-3'>
                    <Card
                       style={{marginBottom:"20px"}}>
                      <img
                        alt="Sample"
                        src={require("../../../Home/src/img/gift-item.jpg")}

                      />
                      <CardBody>
                        <CardTitle tag="h5">
                          Lorem Ipsum
                        </CardTitle>
                        <CardText>
                          Some quick example text to build on the card title and make up the bulk of the card‘s content.
                        </CardText>
                      </CardBody>
                    </Card>
                  </div>
                  <div className='col-md-3'>
                    <Card
                       style={{marginBottom:"20px"}}>
                      <img
                        alt="Sample"
                        src={require("../../../Home/src/img/gift-item.jpg")}

                      />
                      <CardBody>
                        <CardTitle tag="h5">
                          Lorem Ipsum
                        </CardTitle>
                        <CardText>
                          Some quick example text to build on the card title and make up the bulk of the card‘s content.
                        </CardText>
                      </CardBody>
                    </Card>
                  </div>
              </div>
          </div>
      </div>
    </section>
   <CustomFooter FooterData={FooterData} />
  </div>
  )
}

export default Gifting
