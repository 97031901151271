import React from 'react'
import CustomNavbar from "../components/CustomNavbar";
import FooterData from "../components/Footer/FooterData";
import CustomFooter from '../components/Footer/CustomFooter';
import { BiLocationPlus, BiPhone } from 'react-icons/bi';
import { MdMarkEmailUnread } from 'react-icons/md';

function ContactInfo() {
  return (
    <div className="body_wrapper">
    <CustomNavbar
      mClass="menu_four"
      cClass="custom_container p0"
      nClass="pl_120 mr-auto ml-auto"
      hbtnClass="menu_cus"
    />
      <section className='contact-info'>
           <div className='bg-contact-info'>
              <h2 style={{color:"#fff", fontWeight:"500", }}>Contact</h2>
           </div>
          <div className='container'>
              <div className='row'>
                  <div className='col-md-4'>
                      <div className='d-flex' style={{
                          padding:"25px",
                          borderRadius:"10px",
                          boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          marginTop:"40px"
                      }}>
                          <BiPhone size={35} style={{marginRight:"25px"}}/>
                          <h3>Phone
                            <p>(800) 530 2853</p>
                          </h3>
                      </div>
                  </div>
                  <div className='col-md-4'>
                      <div className='d-flex' style={{
                          padding:"25px",
                          borderRadius:"10px",
                          boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          marginTop:"40px"
                      }}>
                          <MdMarkEmailUnread size={35} style={{marginRight:"25px"}}/>
                          <h3>Email
                            <p>Demo@gmail.com</p>
                          </h3>
                      </div>
                  </div>
                  <div className='col-md-4'>
                      <div className='d-flex' style={{
                          padding:"25px",
                          borderRadius:"10px",
                          boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          marginTop:"40px"
                      }}>
                          <BiLocationPlus size={35} style={{marginRight:"25px"}}/>
                          <h3>Address
                            <p>298,Menlo Park,CA,USA</p>
                          </h3>
                      </div>
                  </div>

                  <div className='col-md-12'>
                        <div
                            style={{
                                marginTop:"30px",
                                boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                padding:"20px"
                              }}>
                            <h3 style={{color:"#000", marginBottom:"30px"}}>Get in Touch</h3>
                          <form>
                              <div className='row'>
                                <div className='col-md-4'style={{marginBottom:"20px"}}>
                                    <input type='text' className='form-control' placeholder='Your Name'></input>
                                </div>
                                <div className='col-md-4' style={{marginBottom:"20px"}}>
                                    <input type='email' className='form-control' placeholder='Your Email'></input>
                                </div>
                                <div className='col-md-4' style={{marginBottom:"20px"}}>
                                    <input type='text' className='form-control' placeholder='Your Phone Number'></input>
                                </div>
                                <div className='col-md-12' style={{marginBottom:"30px", marginTop:"10"}}>
                                    <textarea type='text' className='form-control' placeholder='Message...' rows={5} />
                                </div>
                                <div className='col-md-12' style={{marginBottom:"20px"}}>
                                    <button className='btn btn-danger' style={{backgroundColor:"#f40f0f"}}>Submit</button>
                                </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </section>
   <CustomFooter FooterData={FooterData} />
  </div>
  )
}

export default ContactInfo
