import React from "react";

const ErpIconitem = ({ eitemClass, iconName, erpText }) => {
  return (
    <div className="media erp_item">
      <div className={`icon ${eitemClass}`}>
        <i className={`${iconName}`}></i>
      </div>
      <div className="media-body">
        <h5>{erpText}</h5>
      </div>
    </div>
  );
};
export default ErpIconitem;
